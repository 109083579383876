import Head from 'next/head';
import { SliceZone } from '@prismicio/react';
import { createClient } from '../prismicio';

import * as prismic from '@prismicio/client';

import { Layout } from '../components/Layout';
import { Page } from '../components/Page';

import { components } from '../slices';

import { getPageData } from '../helpers/get-data';
import { DEFAULT_LOCALE } from '../config/index.js';

export default function Custom404({ menu, layout, locale, page }) {
  return (
    <Layout menu={menu} layout={layout} locale={locale}>
      <Head>
        <title>{page.data.title}</title>
      </Head>
      <Page
        title={page.data.title}
        description={page.data.description}
        image={page.data.image}
        background={page.data.background}
        theme={page.data.theme}
        locale={locale}
      >
        <SliceZone slices={page.data.body} components={components} />
      </Page>
    </Layout>
  );
}

export async function getStaticProps() {
  const client = createClient();

  const page = await client.getFirst({
    predicates: [
      prismic.predicate.at('document.type', 'page'),
      prismic.predicate.at('my.page.slug', '/404'),
    ],
  });

  const menu = await client.getSingle('menu');
  const layout = await client.getSingle('layout');

  const locale = {
    alternate_languages: await getPageData(
      page.id,
      DEFAULT_LOCALE.locale,
      client
    ),
    current: DEFAULT_LOCALE,
    slug: page.uid,
  };

  return {
    props: {
      menu,
      layout,
      locale,
      page,
    },
  };
}
